.nfl-benefits{
  .xf-content-height{
    margin: 0 auto;
  }

  .nfl-benefits__container{
    > .cmp-container{      
      padding: 48px 15px;
      display: flex;
      align-items: center;
      background-size: initial !important;
      background-repeat: repeat !important;

      @media (min-width: $tablet){
        background-size: cover !important;
        background-repeat: no-repeat !important;
        padding: 60px 15px 73px 15px;
      }     

      @media (min-width: $desktop){
        padding: 64px 0 53.5px 0;
      }
    } 
  }
}