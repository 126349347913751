.nfl-footer--links {
  display: inline;
  width: auto;

  .cmp-text {
    margin: 0;
    display: inline;
    p {
      display: inline;
      margin: 0;
      line-height: 0;
      margin-block: 0;
      a {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: $dark-charcoal;
        margin-right: 24px;
      }

      a:hover {
        text-decoration: none;
      }
    }
  }
}
