.nfl-carousel--title {
    padding: 0 25px;

    @media (min-width: $tablet) {
      padding: 48px 107px 12px 107px;
    }

    @media (min-width: $desktop) {
        padding: 59px 225px 0 225px;
    }

    .cmp-title {
        margin: 0;
    }

    .cmp-title__text {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;
        color: $black;
        text-align: center;

        @media (min-width: $tablet){
            font-size: 35px;
            line-height: 42px;
        }

        @media (min-width: $desktop) {
            font-size: 42px;
            line-height: 48px;
        }
    }
}