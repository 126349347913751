.nfl-club-hero--rewards {
  .cmp-teaser {
    &__content {
      position: static;
      transform: none;
      padding-bottom: 12px;

      @media (min-width: $tablet) {
        padding-bottom: 0;
      }
    }

    &__title {
      margin: 0;
      color: $black;
      text-align: left;
      font-size: 34px;
      font-weight: 400;
      line-height: 44px;

      @media (min-width: $tablet) {
        color: $white;
        font-size: 30px;
        line-height: 48px;
        margin-bottom: 9px;
      }

      @media (min-width: $desktop) {
        font-size: 50px;
        margin-bottom: 8px;
      }
    }

    &__description {
      p {
        margin: 0;
        text-align: left;
        font-weight: 400;
        color: $systemBlack;
        font-size: 15px;
        line-height: 24px;

        @media (min-width: $tablet) {
          color: $white;
          font-size: 16px;
          line-height: 22px;
        }

        @media (min-width: $desktop) {
          line-height: 24px;
        }
      }
    }
  }
}
