.nfl-club-hero--shadow {
  position: relative;
  z-index: 0;
  display: block;
  text-align: center;
  height: 192px;
  box-sizing: content-box;
  margin-top: -231px;
  order: 2;

 @media (min-width: $desktop) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 4;
    margin: 0;
    height: auto;
  }

  .cmp-image {
    position: relative;
    width:203px;
    margin: 0 auto;
    left: 0;
    top: 0;

    @media (min-width: $tablet) {
      width:242px;
    }

    @media (min-width: $desktop) {
      left: 30px;
      top: 186px;
    }

    &__image {
      position: absolute;
      left: 136px;
      top: 125px;
      width: 140px;
      height: 61px;
      opacity: 50%;
      mix-blend-mode: multiply;

      @media (min-width: $tablet) {
        position: absolute;
        top: -2px;
        left: 30px;
        height: 192px;
        width: 444px;
      }

      @media (min-width: $desktop) {
        position: static;
      }
    }
  }
}
