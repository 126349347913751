.nfl-accordion--title {
  width: auto;
  margin-bottom: 16px;

  @media (min-width: $tablet) {
    margin-bottom: 0;
  }

  .cmp-title {
    margin: 0;
    .cmp-title__text {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      text-align: center;
      text-transform: uppercase;
      color: $black;

      @media (min-width: $tablet) {
        font-size: 35px;
        line-height: 40px;
        text-align: left;
        width: 99%;
      }

      @media (min-width: $desktop) {
        font-size: 36px;
        width: 100%;
      }
    }
  }
}
