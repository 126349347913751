.nfl-error__text {
  .cmp-text {
    margin: 0;    

    p {
      margin: 0;
      padding: 16px 0 17px;
      font-family: $font-family-light;
      font-size: 16px;
    }
  }
}
