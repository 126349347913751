.modal {
  .bf-modal {
    display: none;

    &.is-open {
      display: block;
    }

    .bf-modal__overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
    }

    .bf-modal__container {
      background-color: $white;
      margin: 17px;
      border-radius: 8px;
      box-sizing: border-box;
      height: auto;

      @media (min-width: $desktop) {
        max-width: 628px;
        margin: 0px;
      }

      .modal-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 18px 18px 26px 0;

        @media (min-width: $desktop) {
          padding: 26px 26px 34px 0;
        }

        .close-btn {
          cursor: pointer;
          background: url("./resources/images/close-icon.png");
          width: 20px;
          height: 20px;
        }
      }

      .modal-content {
        overflow-y: auto;
        max-height: calc(90vh - 72px);
        padding: 0 25px 0px 25px;
        color: $color-link;
        font-size: $font-size;
        line-height: 24px;

        @media (min-width: $desktop) {
          padding: 0px 56px 0px 56px;
          font-size: 16px;
        }

        h2 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 12px;

          @media (min-width: $desktop) {
            font-size: 22px;
            line-height: 32px;
          }
        }

        h4{
          font-size:15px;
          margin-bottom: 22px;
          font-weight: normal;
          @media (min-width: $desktop) {
            font-size: 16px;
            line-height: 24px;
          }
        }

        p {
          margin: 0;
          font-size:15px;
          line-height: 24px;
          @media (min-width: $desktop) {
            font-size: 16px;
          }
        }

        &__btn-container {
          display: flex;
          justify-content: center;
          margin-top:64.56px;
          margin-bottom: 31.95px;

          a {
            background: $color-link;
            color: $white;
            border-radius: 24px;
            box-shadow: 0px 5px 0px 3px rgb(78 92 111 / 25%);
            cursor: pointer;
            text-align: center;
            width:100%;
            padding: 16px 0px;

            @media (min-width: $desktop) {
              width:auto;
              padding: 16px 60px;
            }
          }
        }
      }
    }
  }
}
