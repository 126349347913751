.nfl-home-hero {
  &__container {
    .cmp-container {
      background-position: center;
      @media (min-width: $tablet){
        padding-bottom: 32px;
      }

      @media (min-width: $desktop){
        padding-bottom: 30px;
      }

      .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
        .cmp-container {
          min-height: initial;
          background-position: initial;
        }
      }
    }
  }

  &__rewards {    
    @media (min-width: $tablet) {
      display: block;
      padding: 0px 29px 2px 27px;
      margin-top: 18px;
    }

    @media (min-width: $desktop) {
      padding: 0px 172px 4px 175px;
      margin-top: 12px;
    }

    .aem-Grid.aem-Grid--12.aem-Grid--default--12::before {
      @media (min-width: $tablet) {
        content: none;
      }
    }

    .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
      @media (min-width: $tablet) {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 26px;
      }

      @media (min-width: $desktop) {
        column-gap: 96px;
      }
    }
  }

  &__rewards-mobile {
    padding: 40px 40px 20px 40px;

    @media (min-width: $tablet) {
      display: none;
    }
  }
}
