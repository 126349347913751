.nfl-sticky-bg-image {
    hr {
        display: none;

        @media (min-width: $tablet) {
            display: block;
            height: 48px;
            margin: 0;
            padding: 0;
            background: url(./resources/images/flood_lights_tablet.png);
        }

        @media (min-width: $desktop) {
            background: url(./resources/images/flood_lights_desktop.png);
        }
    }
}