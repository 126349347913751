.nfl-sticky-wrapper > div.cmp-container {
    display: none;
    width: 100vw;
    min-height: 95px;
    background: $white;
    transform: translate3d(0,0,0);

    @media (min-width: $tablet) {
        min-height: 168px;
    }

    @media (min-width: $desktop) {
        max-width: 1440px;
    }

    .buttonlist {
        .cq-placeholder {
            display: none;
        }
    }
}

.sticky-fixed {
    position: fixed;
    bottom: 0;
}

.nfl-sticky-inner-wrapper > div.cmp-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px 37px;

    @media (min-width: $tablet) {
        padding-left: 162px;
        padding-right: 140px;
        padding-top: 0;
        padding-bottom: 0;
        height: 120px;
    }

    @media (min-width: $desktop) {
        padding-left: 200px;
        padding-right: 200px;
    }
}