.nfl-accordion__faq {
  .cmp-accordion {
    margin: 0;
  }

  .cmp-accordion__button {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border: 0;
    padding: 16px 0;

    @media (min-width: $tablet) {
      padding: 24px 0;
    }

    .cmp-accordion__title {
      color: $black;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      margin-left: 24px;
      white-space: normal;

      @media (min-width: $tablet) {
        margin-left: 33px;
        font-size: 22px;
        line-height: 32px;
      }
    }

    .cmp-accordion__icon:after {
      content: "";
      mask: url("./resources/images/faq-close-icon.png");
      mask-size: cover;
      width: 24px;
      height: 24px;
      background-color: $black;
      vertical-align: 0;
    }
  }

  .cmp-accordion__button--expanded {
    .cmp-accordion__icon:after {
      content: "";
      mask: url("./resources/images/faq-open-icon.png");
      mask-size: cover;
      width: 24px;
      height: 24px;
      background-color: $black;
      vertical-align: 0;
    }
  }

  .cmp-accordion__panel {
    padding: 0 0 17px 48px;

    @media (min-width: $tablet){
      padding: 0 0 17px 59px;
    }

    .cmp-text {
      margin: 0;
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
        font-size: 15px;
        line-height: 24px;
        color: $systemBlack;

        @media (min-width: $tablet) {
          font-size: 16px;
        }
      }

      a {
        font-size: 15px;
        line-height: 24px;
        color: $systemBlack;
        text-decoration: underline;
        font-weight: bold;
        border: 0;

        @media (min-width: $tablet) {
          font-size: 16px;
        }
      }

      a:hover {
        box-shadow: none;
      }

      a:focus {
        box-shadow: none;
      }

      i {
        font-style: italic;
      }

      ol {
        font-size: 15px;
        line-height: 24px;
        color:$systemBlack;
        
        @media (min-width: $tablet) {
          font-size: 16px;
        }
        li {
          list-style: decimal;
        }
      }

      ul {
        font-size: 15px;
        line-height: 24px;
        list-style: decimal;
        color:$systemBlack;
        
        @media (min-width: $tablet) {
          font-size: 16px;
        }

        li::marker {
          content: "•  ";
          font-size: 15px;

          @media (min-width: $tablet) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
