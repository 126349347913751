.nfl-header--logo {
  display: flex;
  justify-content: flex-start;
  padding: 9.26px 0 7.58px 0;

  @media (min-width: $tablet) {
    padding: 11.46px 0 4.55px 0;
  }

  @media (min-width: $desktop) {
    padding: 12px 0 5.6px 0;
  }

  div {
    margin: 0;
  }

  img {
    width: 137.02px;
    height: 47.16px;

    @media (min-width: $tablet) {
      width: 142.94px;
      height: 50.99px;
    }

    @media (min-width: $desktop) {
      width: 175px;
      height: 62.4px;
    }
  }
}
