.nfl-benefits--title {
  .cmp-title {
    margin: 0;

    @media (min-width: $tablet) {
      padding: 0 35px;
      margin-bottom: 20px;
    }

    @media (min-width: $desktop) {        
      padding: 0 22%;
      margin-bottom: 10px;
    }

    &__text {
      font-weight: 400;
      font-style: normal;
      text-align: center;
      text-transform: uppercase;
      color: $white;      
      font-size: 30px;
      line-height: 32px;

      @media (min-width: $tablet) {
        font-size: 35px;
        line-height: 38px;
      }

      @media (min-width: $desktop) {        
        font-size: 42px;
        line-height: 48px;
      }
    }
  }
}
