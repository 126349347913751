.nfl-home-hero--title {
  .cmp-title {
    margin: 0;

    .cmp-title__text {
      font-weight: 400;
      font-style: normal;
      text-align: center;
      color: $white;
      padding: 36px 34px 20px;
      font-size: 40px;
      line-height: 42px;

      @media (min-width: $tablet) {
        padding: 40px 1px 22px;
        font-size: 45.7947px;
        line-height: 53px;
      }

      @media (min-width: $desktop) {
        padding: 42px 299px 5px 301px;
        font-size: 54px;
        line-height: 62px;
      }
    }
  }
}
