.nfl-sticky-brand-image {
    display: none;

    @media (min-width: $tablet) {
        display: initial;
    }

    .cmp-image {
        margin: 0;
    }

    .cmp-image__image {
        width: 143.19px;
        height: 160px;
        position: relative;
        bottom: 25px;
    }
}