.nfl-accordion {
  &__container {
    padding: 52px 16px 31px;
    box-sizing: border-box;

    @media (min-width: $tablet) {
      padding: 60px 38px;
    }

    @media (min-width: $desktop) {
      padding: 70px 188px 35px;
    }

    .xf-content-height {
      margin: 0;
    }
  }

  &__container_wrapper {
    padding: 32px 24px 19px 24px;
    background: #ffffff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
    border-radius: 30px;

    @media (min-width: $tablet) {
      padding: 64px 64px 42px 46px;
    }

    @media (min-width: $desktop) {
      padding: 64px 109px;
    }
  }

  &__header {
    .aem-Grid.aem-Grid--12.aem-Grid--default--12::before {
      content: none;
    }

    .aem-Grid.aem-Grid--12.aem-Grid--default--12::after {
      content: none;
    }

    .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 36px;

      @media (min-width: $tablet) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 21px;
      }

      @media (min-width: $desktop) {
        margin-bottom: 63px;
      }

      .aem-GridColumn.aem-GridColumn--default--12 {
        width: auto;
        .aem-GridColumn.aem-GridColumn--default--12 {
          width: 100%;
        }
      }
    }
  }
}
