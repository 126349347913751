.nfl-header__card {
  .cmp-container {
    padding: 13px 0 19px 0;

    @media (min-width: $tablet) {
      padding: 15px 0 20px 0;
    }

    @media (min-width: $desktop) {
      padding: 21px 0 27px 0;
    }
  }
  .buttonlist {
    div {
      display: flex;
      justify-content: flex-end;
    }

    a {
      padding:8px 0;
      text-decoration: none;
    }
  }
}
