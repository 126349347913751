.nfl-carousel--description {
    padding: 24px 40px 8px 40px;

    @media (min-width: $tablet) {
      padding: 8px 54px;
    }

    @media (min-width: $desktop) {
        padding: 20px 282px;
    }

    .cmp-text {
      margin: 0;
      p {
        margin: 0;
        font-family: $font-family-light;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: $dark-charcoal;
      }
    }
}