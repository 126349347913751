.nfl-home-hero__more-details {
  .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
    display: flex;
    box-sizing: border-box;

    @media (min-width: $tablet) {
      padding: 0 48px;
    }

    @media (min-width: $desktop) {
      padding: 0 218px;
    }
  }
  
  .buttonlist {
    div {
      display: flex;
      justify-content: center;
      @media (min-width: $tablet) {
        justify-content: flex-end;
      }
    }

    a {
      color:$black;
      border-color:black;
      padding: 8px 0 7px 0;
      @media (min-width: $tablet) {
        color: $white;
        border-color: $white;
      }
    }
  }
}
