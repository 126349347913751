.nfl-benefits--description {
  > .cmp-container{  
    margin: auto;

    @media (min-width: $tablet){
      width: 75%;
    }

    @media (min-width: $desktop) {
      width: 65%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;            
    }

    .nfl-benefits__image--text, 
    .nfl-benefits__image--description {
      float: none;      
      margin-top: 32px;
      padding: 0 30px;

      @media (min-width: $tablet){
        margin-top: 25px;
        padding: 0;
      }
      
      @media (min-width: $desktop){
        width: 46%;
        margin-top: 35px; 
        padding: 0;
      }

      .cmp-teaser{
        display: flex;
        justify-content: space-between;
        flex-flow: column-reverse;   
        @media (min-width: $tablet){
          flex-flow: row-reverse;
          justify-content: flex-end;
        }

        @media (min-width: $desktop){
          flex-flow: row-reverse;           
        }

        &__content{
          position: relative;
          left: 0;
          transform: none;
          
          @media (min-width: $tablet){            
            padding-left: 96px;
          }

          @media (min-width: $desktop){            
            padding-left: 101px;
          }
          
          &::before{
            content: "";
            width: 63px;
            height: 63px;
            background: url("./resources/images/plus-icon.png") no-repeat center top;
            background-size: 100%;   
            display: flex;
            margin: auto; 
            
            @media (min-width: $tablet){
              position: absolute;
              left: 0;
              top: -13px;              
            }
          }
  
          .cmp-teaser__title{
            font-size: 26px;
            line-height: 32px;
            font-weight: 400;            
            margin-top: 24px;
            margin-bottom: 0;            

            @media (min-width: $tablet){
              text-align: left;
              margin-top: 0;
              font-size: 30px;
            }
          }
  
          .cmp-teaser__description{
            @media (min-width: $tablet){
              text-align: left;
            }

            @media (min-width: $desktop){              
              width: 99%;
            }

            p {
              font-size: 18px;
              line-height: 24px;   
              margin-top: 16px;           
            }
          }
        }

        &__image{
          width: 15%;
          margin: auto;
          @media (min-width: $tablet){
            width: 10%;
            margin: 0;
            margin-right: 30px;
          }

          @media (min-width: $desktop){
            width: 12%;
            margin: 0;
            margin-right: 30px;
          }
          img{
            width: 100%;
            height: auto;
          }
        }
      }        
    }

    .nfl-benefits__image--description {
      .cmp-teaser {
        display: block;
        .cmp-teaser__description {
          font-family: 'All-ProSans';
          h1, h2, h3, h4, h5, h6, p {
            font-size: 26px;
              line-height: 32px;
              font-weight: 400;            
              margin-top: 24px;
              margin-bottom: 0;
              text-align: center;
              color: #fff;      

              @media (min-width: $tablet) {
                text-align: left;
                margin-top: 0;
                font-size: 30px;
              }
          }
        }
      }
    }    
  }
}
