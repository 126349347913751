.nfl-club-hero--image {
  position: relative;
  z-index: 1;
  margin-top: 34px;
  order: 2;

  @media (min-width: $tablet){
    margin-top: 0;
  }

  @media (min-width: $desktop) {
    grid-row-start: 1;
    grid-column-start: 2;
    grid-row-end: 4;
  }

  .cmp-image {
    margin: 0;
    text-align: center;
    padding-bottom: 37px;

    @media (min-width: $tablet) {
      padding-bottom: 38px;
    }

    @media (min-width: $desktop) {
      padding-bottom: 0px;
    }

    &__image {
      display: inline-block;
      width: 203px;
      height: 317.19px;

      @media (min-width: $tablet) {
        width: 241.92px;
        height: 378px;
      }
    }
  }
}
