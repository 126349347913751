.nfl-team-picker--shadow {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height: 192px;
    margin-top: -321px;
    box-sizing: content-box;
    position: relative;
    z-index: 0;
    
  .cmp-image {
    margin: 0;
    width: 242px;
    position: relative;
  
    &__image {
      position: absolute;
      top: 125px;
      left: 156px;
      width: 140px;
      height: 60px;
      opacity: 50%;
      mix-blend-mode: multiply;

      @media (min-width: $tablet) {
        top: 4px;
        left: 30px;
        width: 444px;
        height: 192px;
      }

      @media (min-width: $desktop) {
        top: 0;
        left: 29px;
      }
    }
  }
}