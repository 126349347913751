.nfl-hero--subtitle{
  .cmp-text{
    margin: 0;

    p{
      margin: 0;
      text-align: center;
      color: $white;
      font-family: $font-family-light;
      font-size: 24px;
      line-height: 30px;
      max-width: 80%;
      margin: auto;

      @media (min-width: $tablet){
        font-size: 34px;
        line-height: 38px;
        max-width: 60%;        
      }
      
      @media (min-width: $desktop){    
        font-size: 36px;    
        line-height: 62px;
      }
    }
  }
}

.nfl-club-hero{
  &__container{
    .nfl-hero--subtitle{
      .cmp-text{
        p{
          @media (min-width: $desktop){          
            text-align: left;
            max-width: none;
            line-height: 40px;
          }
        }
      }
    }
  }
}
