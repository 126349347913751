.nfl-club-hero--title {
    @media (min-width: $desktop) {
    margin: 44px 0 20px;
  }

  height: min-content;

  .cmp-title {
    margin: 0;

    .cmp-title__text {
      color: $white;
      font-weight: 400;
      font-size: 40px;
      line-height: 42px;      
      text-align: center;
      padding: 32px 21px 16px 16px;

      @media (min-width: $tablet) {
        padding: 38px 1px 14px 1px;
        font-size: 45.7947px;
        line-height: 53px;
      }

      @media (min-width: $desktop) {
        padding: 0;
        font-size: 54px;
        line-height: 64px;
        text-align: left;
      }
    }
  }
}
