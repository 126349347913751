.nfl-error__container{  
  margin-top: 46px;

  @media (min-width: $tablet){
    margin-top: 80px;
  }

  > .cmp-container{      
    padding-left: 16px;
    padding-right: 18px;

    @media (min-width: $tablet){
      padding-left: 38px;
      padding-right: 34px;
    }

    @media (min-width: $desktop){
      padding-left: 90px;
      padding-right: 60px;
    }

    > .aem-Grid{
      box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
      border-radius: 30px;
      padding: 35px 36px 43px;
      width: auto;

      @media (min-width: $tablet){
        padding: 64px 89px 80px;        
      }
    }    
  }
}