.nfl-club-hero__btns {
  order: 3;
  margin-top: 39px;

  @media (min-width: $desktop) {
    margin-top: 0;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-row-end: 3;
  }

  .buttonlist {
    .btn-primary {
      margin-bottom: 18px;
      padding: 16px 0;
      width: 300px;

      @media (min-width: $tablet) {
        margin-bottom: 0;
        padding: 16px 0;
        width: 166px;
      }
    }

    .link-secondary {
      margin-bottom: 30px;
      @media (min-width: $tablet) {
        margin-bottom: 0;
      }

      .cta-button__text {
        display: inline-block;
        padding: 8px 0;
        border-bottom: 1px solid $white;
      }
    }

    a {
      text-decoration: none;
      border: 0;

      @media (min-width: $tablet) {
        margin-right: 28px;
      }
      
      @media (min-width: $desktop) {
        margin-right: 29px;
      }
    }
  }
}
