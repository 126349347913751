.nfl-team-picker__image-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding-bottom: 36px;
    height: 316px;
    margin-top: 26px;
    box-sizing: content-box;
    position: relative;
    z-index: 1;

    @media (min-width: $tablet) {
        height: 377.08px;
        margin-top: 0;
        padding-bottom: 30px;
    }

    @media (min-width: $desktop) {
        padding-bottom: 34px;
    }

    .cmp-image {
        margin: 0;
        background: url("./resources/images/loader-1x1.png") center center no-repeat transparent;
        background-size: contain;
        width: 203px;
        height: 316px;

        @media (min-width: $tablet) {
            width: 241.33px;
            height: 377.08px;
        }
    }

    .cmp-image__image {
        width: 203px;
        height: 316px;

        @media (min-width: $tablet) {
            width: 241.33px;
            height: 377.08px;
        }
    }
}