@import "../../../site/variables";

// =================
// Adding CSS for dropdown
.ui-menu .ui-state-active {
    margin: 0;
}

.ui-menu-item-wrapper {
    padding: 0;
}

.ui-menu .ui-menu-item {
    padding: 0;
    border: none;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}

.ui-widget-content {
    -moz-border-radius: 0 0 24px 24px;
    -webkit-border-radius: 0 0 24px 24px;
    border-radius: 0 0 24px 24px;
    padding-bottom:30px;
    min-height: 247px;
    max-height: 248px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

.ui-widget-content::-webkit-scrollbar {
    width: 14px;
}
 
.ui-widget-content::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 100px;
}
 
.ui-widget-content::-webkit-scrollbar-thumb {
    background-color: $light-grey;
    border-radius: 100px;
}

.ui-widget > .ui-widget-content {
    box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2);
}

.ui-menu .ui-menu-item-wrapper {
    padding: 7px 7px 7px 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $dark-charcoal;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: none;
    border-right: none;
    transition: all .1s ease-in-out;
}

.ui-menu .ui-state-active {
    margin: 0px;
}

.ui-state-active, .ui-widget-content .ui-state-active {
    background: rgba(0, 0, 0, 0.05);
    border-top: 1px solid rgba(0, 0, 0, 0.5);
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-left: none;
    border-right: none;
    transition: all .1s ease-in-out;
}
