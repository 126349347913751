.nfl-club-hero {
  .xf-content-height {
    margin: 0;
  }

  &__container {
    > .cmp-container {
      min-height: 515px;
      background-position: center;

      .aem-Grid.aem-Grid--12.aem-Grid--default--12::before {
        content: none;
      }

      > .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
        position: relative;
        display: flex;
        flex-direction: column;

        .cmp-container {
          min-height: initial;
          background-position: initial;
        }

        @media (min-width: $desktop) {
          display: grid;
          padding: 66px 114px 68px 114px;
          column-gap: 52px;
          box-sizing: border-box;
          grid-template-rows: auto 1fr;
          grid-template-columns: 1fr auto 1fr;
        }
      }

      .nfl-hero--subtitle {
        .cmp-text {
          @media (min-width: $desktop) {
            margin: 0 0 46px 0;
          }          
        }
      }
    }
  }

  &__rewards {
    order: 1;

    @media (min-width: $tablet) {
      margin-top: 10px;
    }

    @media (min-width: $desktop) {
      grid-row-start: 1;
      grid-column-start: 3;
      grid-row-end: 4;
      margin-top: 50px;
    }

    .cmp-container {
      > .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
        padding: 0;
        column-gap: 0;
        display: flex;
        box-sizing: border-box;

        @media (min-width: $desktop) {
          display: flex;
          padding: 0;
          flex-direction: column;
        }

        .nfl-club-hero--rewards {
          @media (min-width: $tablet) {
            padding: 0 28px 22px 28px;
          }

          @media (min-width: $desktop) {
            margin-bottom: 25px;
            padding: 0;
          }
        }
      }
    }
  }

  &__btns {
    .cmp-container {
      .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
        padding: 0;
        column-gap: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media (min-width: $tablet) {
          flex-direction: row;
          margin-bottom: 36.89px;
        }

        @media (min-width: $desktop) {
          justify-content: flex-start;
          margin-bottom: 0;
          row-gap: 20px;
          flex-flow: wrap;
        }

        .aem-GridColumn.aem-GridColumn--default--12 {
          width: 100%;

          @media (min-width: $tablet) {
            width: auto;
          }
        }

        .buttonlist.aem-GridColumn.aem-GridColumn--default--12 {
          width: auto;
        }

        .nfl-team-picker__typeahead{

          @media (min-width: $tablet){
              padding-left: 0;
              width: auto;
          }

          &__apply-now{
            @media (min-width: $tablet){
                padding-left: 0;
            }
          }
        }
      }
    }
  }

  &__rewards-mobile {
    padding: 40px 40px 28px 40px;

    @media (min-width: $tablet) {
      display: none;
    }
  }
}