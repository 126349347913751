.nfl-footer {
  padding: 15px 0 30px 0;
  margin-bottom: 40px;

  @media (min-width: $tablet) {
    margin-bottom: 0;
    padding-top: 0;
  }

  @media (min-width: $desktop) {
    padding: 25px 0 35px 0;
  }

  .xf-content-height {
    margin: 0;
  }

  &__link-wrapper {
    padding: 32px 16px 24px 16px;

    @media (min-width: $tablet) {
      padding: 32px 41px 24px 41px;
    }

    @media (min-width: $desktop) {
      padding: 32px 80px 24px 80px;
    }

    .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--12 {
      width: auto;
    }
  }
}
