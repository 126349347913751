.nfl-carousel__image-only {
    height: auto;
    .cmp-image__image {
        width: 290px;
        height: 200px;
        margin: 0 auto;
        object-fit: unset;
        border-radius: 28.8516px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    }

    .cmp-teaser__title {
        font-size: 21.1578px;
        line-height: 31px;
        color: $dark-charcoal;
        font-weight: normal;
        padding: 24px;
        margin: 0;
        text-align: left;
      }
      .cmp-teaser__content {
        display: none;
      }
}
