.buttonlist {
  .cta-button--link {
    cursor: pointer;
    padding-bottom: 6px;
    display: inline-block;
  }

  .link-primary {
    color: $black;
    border-bottom: 1px solid $black;
    font-weight: 400;
    font-size: $font-size;
    line-height: $font-height;
  }

  .link-secondary {
    color: $white;
    border-bottom: 1px solid $white;
    font-weight: 500;
    font-size: $font-size;
    line-height: $font-height;
  }

  .cta-button--btn {
    display: inline-block;
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
    font-style: normal;
    padding: 16px 36.5px;
    border-radius: 24px;
    text-align: center;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
  }

  .btn-primary{
    background: linear-gradient(180deg, #DDC274 0%, #9B7B2D 100%);
    box-shadow: 0px 5px 0px 3px rgba(0, 0, 0, 0.25);
  }
}