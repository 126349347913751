//== XF Header style, used on page template

.nfl-header {
  .xf-content-height {
    margin:0;
  }

  #main-header {
    background-color: $color-background;
    padding-left: 16px;
    padding-right: 18px;

    @media (min-width: $tablet) {
      padding-left: 38px;
      padding-right: 34px;
    }

    @media (min-width: $desktop) {
      padding-left: 90px;
      padding-right: 66px;
    }

    .aem-Grid.aem-Grid--12.aem-Grid--default--12 {
      display: flex;
    }
  }
}