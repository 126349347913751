.nfl-error__button{
  .cmp-button{
    display: inline-block;
    margin: 0;
    text-decoration: none;
    padding: 16px 47px;
    background-color: $black;
    color: $white;
    border-radius: 24px;
    box-shadow: 0px 5px 0px 0px rgba(78, 92, 111, 0.25);

    @media (min-width: $tablet) {
      padding: 15px 55px;
    }

    &__text{
      font-size: 16px;
      font-weight: 400;
    }
  }
}
