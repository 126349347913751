.nfl-carousel__image-with-title {
    border: 1px solid $light-grey;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    margin: 5px;
    height: 312px;

    @media (min-width: $tablet) {
      margin: 5px;
    }

    @media (min-width: $desktop) {
      margin: 12px;
    }

    .cmp-image__image {
        width: 100%;
        height: 200px;
        margin: 0 auto;
        object-fit: unset;

        @media only screen and (min-width: 821px) and (max-width: 1100px)  {
          object-fit: contain; 
        }

        @media only screen and (min-width: 1101px) and (max-width: 1400px)  {
          object-fit: none; 
        }

        @media only screen and (min-width: 1401px)  {
          object-fit: unset;
        }
    }

    .cmp-teaser__title {
        font-size: 22px;
        line-height: 32px;
        color: $dark-charcoal;
        font-weight: 600;
        padding: 20px 28px;
        margin: 0;
        text-align: left;
      }
      .cmp-teaser__content {
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        transform: none;
        a {
          color: $dark-charcoal;
        }
      }
}