.nfl-error__title {
  .cmp-title {
    margin: 0;

    &__text {
      font-weight: 400;
      font-style: normal;      
      text-transform: uppercase;
      font-size: 28px;
      line-height: 32px;

      @media (min-width: $tablet) {
        font-size: 35px;
        line-height: 38px;
      }

      @media (min-width: $desktop) {        
        font-size: 36px;
        line-height: 40px;
      }
    }
  }
}
