.nfl-home-hero--rewards {
  .cmp-teaser__content {
    position: static;
    transform: none;
    padding-bottom: 16px;

    @media (min-width: $tablet) {
      padding-bottom: 0;
    }

    .cmp-teaser__title {
      margin: 0;
      text-align: left;
      font-style: normal;
      font-weight: 400;
      color: $black;
      font-size: 34px;
      line-height: 44px;

      @media (min-width: $tablet) {
        color: $white;
        font-size: 30px;
        line-height: 48px;
        padding-bottom: 0;
      }

      @media (min-width: $desktop) {
        font-size: 38px;
      }
    }

    .cmp-teaser__description {
      p {
        margin: 0;
        text-align: left;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $systemBlack;

        @media (min-width: $tablet) {
          font-size: 16px;
          line-height: 24px;
          color: $white;
        }
      }
    }
  }
}
