.nfl-team-picker {
    .cmp-container {
        padding-bottom: 10px;
        position: relative;

        @media (min-width: $tablet){
            padding-bottom: 18px;
        }

        @media (min-width: $desktop){
            padding-bottom: 10px;
        }
    }
    &__typeahead {
        display: flex;
        flex-direction: column;
        padding-left: 38px;
        padding-right: 37px;

        @media (min-width: $tablet) {
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }

        &__wrapper {
            display: inherit;
            &--input {
                height: 44px;
                width: 100%;
                border: 1px solid $dark-charcoal;
                box-shadow: 0px 0px 30px 2px rgba(0, 0, 0, 0.2);
                border-radius: 24px;
                margin-bottom: 22px;
                padding-left: 26px;
                padding-right: 45px;
                font-size: 18px;
                background: url("./resources/images/down-arrow.png") right 15px bottom 45% no-repeat $white;
                transition: all .2s ease-in-out;

                @media (min-width: $tablet) {
                    margin-bottom: 0;
                    min-width: 306px;
                }

                &.error{
                    border-color: $error-darker;
                    background: url("./resources/images/down-arrow-error.png") right 15px bottom 45% no-repeat $white;
                    &::placeholder{
                        color: $error-darker;
                    }
                }
            }
    
            &--input::placeholder {
                color: $black;
                font-size: 18px;
            }

            &--input:focus {
                border: 1px solid $silver;
                border-radius: 24px 24px 0 0;
                background: url("./resources/images/up-arrow.png") right 15px bottom 50% no-repeat $white;
                transition: all .2s ease-in-out;
                outline: none;
            }
        }

        &__apply-now {
            display: inherit;
            padding-left: 0;
            margin-bottom: 30px;

            @media (min-width: $tablet) {
                padding-left: 17px;
                margin-bottom: 0;
            }

            .btn{
                width: 100%;
                > div{
                    > div{
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;                
                        font-size: 16px;
                        line-height: 16px;
                        text-transform: uppercase;
                        width: 100%;
                        height: 48px;
                        background: linear-gradient(180deg, #DDC274 0%, #9B7B2D 100%);
                        box-shadow: 0px 5px 0px 3px rgba(0, 0, 0, 0.25);
                        border-radius: 24px;

                        @media (min-width: $tablet){
                            width: 156px;
                        }
                    }
                }                
            }
        }
    }
}
