.nfl-carousel > div.cmp-container {
    padding: 20px 42px 47px 42px;

    @media (min-width: $tablet) {
        padding: 20px 80px 55px 80px;
    }

    @media (min-width: $desktop) {
        padding: 4px 104px 58px 104px;
    }
}