.nfl-sticky-title {
    display: none;

    @media (min-width: $desktop) {
        display: inherit;
    }

    .cmp-title {
        margin: 0;
    }

    .cmp-title__text {
        margin: 0;
        padding-top: 10px;
        margin-right: 15px;
        font-size: 30px;
        line-height: 32px;
        font-weight: bold;
    }

}