.nfl-footer--description {
    padding: 0 16px;

    @media (min-width: $tablet) {
      padding: 0 41px 0 41px;
    }

    @media (min-width: $desktop) {
      padding: 0 80px 0 80px;
    }

    .cmp-text {
      margin: 0;
      p {
        margin: 0;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $dark-grey;

        a {
          font-size: 14px;
          line-height: 18px;
          color: $systemBlack;
          text-decoration: underline;
          font-weight: bold;
          border: 0;
  
          @media (min-width: $tablet) {
            line-height: 16px;
          }
        }
  
        a:hover {
          box-shadow: none;
        }
  
        a:focus {
          box-shadow: none;
        }
      }
    }
}