.nfl-accordion--text {

  &:nth-child(3) {
    display: none;
  }

  .cmp-text {
    margin: 0;
    min-width: 79px;
    width: 65px;
    height: 32px;

    p {
      margin: 0;

      height: 32px;
      a {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        padding: 8px 0 7px 0;
        text-decoration: none;
        border-bottom: 1px solid $black;
        border-radius: 0;
      }

      a:focus {
        box-shadow: none;
      }

      a:hover {
        box-shadow: none;
      }
    }
  }
}
