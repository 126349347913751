.nfl-footer--logo {
    padding: 0 16px 30px 16px;
    width: auto;

    @media (min-width: $tablet) {
      padding: 0 41px 32px 41px;
    }

    @media (min-width: $desktop) {
        padding: 0 80px 32px 80px;
      }
    .cmp-image {
        margin: 0;

        &__image {
            width: 200px;
        }
    }
}